import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Link } from 'react-router-dom';
import config from '../coreFIles/config';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'
import { getTopSalesListAction, getTopProductsListAction, getTopBuyersListAction, getindustryRevenueAction, getdateRangeAction }  from '../Action/action';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
const Dashboard = () => {
	const [page, setPage] 	= useState(1); 
	const [charLoader, setcharLoader] = useState(0); 
	const [sales, setSales] = useState({});
    const [topBuyers, setTopBuyers] = useState([]);
    const [topProducts, setTopProducts] = useState([]);
    const [industryRevenue, setindustryRevenue] = useState();
    const [yAxisMax, setyAxisMax] = useState(0);
   
	useEffect(()=>{
	  getSalesAPI();  
	  getTopBuyersAPI();  
	  getTopProductsAPI();  
	  getindustryRevenueAPI();  
    },[]);
    
   const getSalesAPI = async () => {
        let res = await getTopSalesListAction();
        if (res.success) {
             setSales(res.data);      
		}else{
			 setSales({});
		}
    }
    
    const getTopBuyersAPI = async () => {
        let res = await getTopBuyersListAction();
        if (res.success) {
             setTopBuyers(res.data);      
		}else{
			 setTopBuyers({});
		}
    }
    
    const getTopProductsAPI = async () => {
        let res = await getTopProductsListAction();
        if (res.success) {
             setTopProducts(res.data);      
		}else{
			 setTopProducts({});
		}
    }
    
    const getindustryRevenueAPI = async () => {
        let res = await getindustryRevenueAction();
        if (res.success) {
			  const data = res.data;			 
			  setindustryRevenue(data);
			  const maxRevenue = Math.max(...data.map(item => parseFloat(item.revenue)));
			  const yAxisMax = Math.ceil(maxRevenue / 1000) * 1000;
			  setyAxisMax(yAxisMax);
              setcharLoader(1);
		}else{
			 setindustryRevenue({});
		}
    }
    
    const formatDate = (date) => {
	  return date.toISOString().split("T")[0]; 
	};
	const [dateRange, setDateRange] = useState({start: '2024-10-01',end: formatDate(new Date())});
	const [stats, setStats] = useState({});		
	   useEffect(() => {
			const  start = dateRange.start;
			const  end = dateRange.end;
			getdateRangeAPI(start,end);		
	  }, [dateRange]);
  
   const getdateRangeAPI = async (startDate,endDate) => {
        let res = await getdateRangeAction({startDate:startDate,endDate:endDate});
        if (res.success) {
			  let data = res.data;
			  setStats(data);
		}else{
			 setStats({});
		}
    }
    
  const handleDateChange = (e) => {
		const { name, value } = e.target;
		setDateRange(prev => ({
		  ...prev,
		  [name]: value
		}));
  };
  
  return (

     <>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light dash-wrapper ">
         <Toaster />
        <Header />
        
        <div className="body flex-grow-1 px-3">
        <div className="container-lg mb-5">
          <div className="row">
           <p className="fnt-fmly-silka">Welcome to Fastner Warehouse Admin Section</p>  
           <div className="col-md-4"> 
				<div className="card-body inner-body cardbdy">
					<h4>Sales</h4>
					<Tabs>
						<TabList>
							<Tab><span className="fnt-15">Today</span></Tab>
							<Tab><span className="fnt-15">Week</span></Tab>
							<Tab><span className="fnt-15">Month</span></Tab>
							<Tab><span className="fnt-15">Year</span></Tab>
							<Tab><span className="fnt-15">Range</span></Tab>
						</TabList>
						<TabPanel className="padd-lft">
							<p className="mt-3 fnt-18">Total Sales : <span>AU${sales.dailySales != null ? sales.dailySales:0}</span></p>
							<p className="mt-3 fnt-18">Orders : <span>{sales.dailyOrders != null ? sales.dailyOrders:0}</span></p>
							<p className="mt-3 fnt-18">Product Sold : <span>{sales.dailyProducts != null ? sales.dailyProducts:0}</span></p>
						</TabPanel>
						<TabPanel className="padd-lft">
							<p className="mt-3 fnt-18">Total Sales : <span>AU${sales.weeklySales != null ? sales.weeklySales:0}</span></p>
							<p className="mt-3 fnt-18">Orders : <span>{sales.weeklyOrders != null ? sales.weeklyOrders:0}</span></p>
							<p className="mt-3 fnt-18">Product Sold : <span>{sales.weeklyProducts != null ? sales.weeklyProducts:0}</span></p>
						</TabPanel>
						<TabPanel className="padd-lft">
							<p className="mt-3 fnt-18">Total Sales : <span>AU${sales.monthlySales != null ? sales.monthlySales:0}</span></p>
							<p className="mt-3 fnt-18">Orders : <span>{sales.monthlyOrders != null ? sales.monthlyOrders:0}</span></p>
							<p className="mt-3 fnt-18">Product Sold : <span>{sales.monthlyProducts != null ? sales.monthlyProducts:0}</span></p>
						</TabPanel>
						<TabPanel className="padd-lft">
							<p className="mt-3 fnt-18">Total Sales : <span>AU${sales.yearlySales != null ? sales.yearlySales:0}</span></p>
							<p className="mt-3 fnt-18">Orders : <span>{sales.yearlyOrders != null ? sales.yearlyOrders:0}</span></p>
							<p className="mt-3 fnt-18">Product Sold : <span>{sales.yearlyProducts != null ? sales.yearlyProducts:0}</span></p>
						</TabPanel>
						<TabPanel className="padd-lft">	
							<div className="mt-4">						
								<input
									  id="start-date"
									  type="date"
									  name="start"
									  value={dateRange.start}
									  onChange={handleDateChange}
									  min="2024-10-01"
									  max={dateRange.end}
									  className="w-full px-3 py-2 border rounded-md"
									/>
									&nbsp;&nbsp;
									<input
									  id="end-date"
									  type="date"
									  name="end"
									  value={dateRange.end}
									  onChange={handleDateChange}
									  min={dateRange.start}
									  max={formatDate(new Date())}
									  className="w-full px-3 py-2 border rounded-md"
									/>
								</div>
								<p className="mt-5 fnt-18">Total Sales : <span>AU${stats.Sales != null ? stats.Sales:0}</span></p>
								<p className="mt-3 fnt-18">Orders : <span>{stats.Orders != null ? stats.Orders:0}</span></p>
								<p className="mt-3 fnt-18">Product Sold : <span>{stats.Products != null ? stats.Products:0}</span></p>
						
											
						</TabPanel>
					</Tabs>
				</div>
                  
           </div>
           <div className="col-md-4"> 
				<div className="card-body inner-body cardbdy statsgrid">
					<h4>Top Products</h4>
					<ul>
					<li className="heading">
					<div className="w10">#</div>
					 <div className="w50">Hobson Part</div>
					 <div className="w40">Total Earnings</div>
					 </li>
					{topProducts.map((product, index) => (
							<li key={product.product_id}>
								<div className="w10">{index + 1} </div>
								<div className="w50"><Link to={`${config.baseUrl}edit-product/` + product.product_id} target="_blank"
									rel="noopener noreferrer">{product.hobson_part}
								</Link></div>
								<div className="w40">${parseFloat(product.total_earnings).toFixed(2)}</div>
							</li>
						))}						
					</ul>
					
				</div>
                  
           </div>
           <div className="col-md-4"> 
				<div className="card-body inner-body cardbdy statsgrid">
					<h4>Top Buyers</h4>
					 <ul>
					 <li className="heading">
						<div className="w10">#</div>
						<div className="w50">Customer Name</div>
						<div className="w40">Total Spent</div>
					 </li>
					  {topBuyers.map((buyer, index) => (
							<li key={buyer.user_id}>
								<div className="w10">{index + 1} </div>
								<div className="w50"><Link to={`${config.baseUrl}edit-user/` + buyer.user_id} target="_blank"
									rel="noopener noreferrer">
								{buyer.name}
								</Link></div>
								<div className="w40">${parseFloat(buyer.total_spent).toFixed(2)}</div>
							</li>
						))}						
					</ul>
				</div>                  
           </div>
           <div className="col-md-10 mt-5"> 				
				<div className="card-body inner-body cardbdy statsgrid ">	
				<h4>Industries Revenue</h4>		
				<div className="chart-container">	
					 <ResponsiveContainer width="100%" height="100%">
						  <BarChart data={industryRevenue} margin={{ top: 20, right: 30, left: 20, bottom: 2 }}>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="industry_name" tickLine={{ display: false }}/>							
							<YAxis
								domain={[0, yAxisMax]} // Set the Y-axis domain to include the calculated max
								tickCount={8}
								tickFormatter={(value) => `$${value.toFixed(2)}`}
							  />
							<Tooltip cursor={{fill: 'transparent'}} formatter={(value) => `$${parseFloat(value).toFixed(2)}`} />
							<Bar dataKey="revenue" fill="#EB600A" />
						  </BarChart>
						</ResponsiveContainer>				
				</div> </div>                 
           </div>
           
        </div>
        </div>
      </div>    
      <Footer />
      </div>
    </>
  )

}
export default Dashboard;
