const config = {
  baseUrl: '/',
  //~ imageUrl: "http://localhost:3008/images",
  //~ apiUrl: "http://localhost:3008/api",
  imageUrl: "https://orders.fastenerwarehouse.com.au/apis/api/uploads",
  apiUrl: "https://orders.fastenerwarehouse.com.au/apis/api",
  QrtoCartUrl: "https://orders.fastenerwarehouse.com.au/add-product-to-cart/",
  superAdmin:1,
  role_admin:1,
  role_telemkt:2,
  days_since_registration:60,
  paging_size:20
}
export default config;
