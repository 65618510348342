import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  postRequestFormData,
} from "../coreFIles/helper"; 

export const LoginAction = (data) => {
  return postRequest("adminLogin", data).then((res) => {
    return res.data;
  });
};
export const getsettingsListAction = (data) => {
  return postRequest("getsettingsList", data).then((res) => {
    return res.data;
  });
};
export const updatesettingsListsAction = (data) => {
  return postRequest("updatesettingsLists", data).then((res) => {
    return res.data;
  });
};

// Admin Dashboard
export const getTopSalesListAction = (data) => {
  return postRequest("getTopSalesList", data).then((res) => {
    return res.data;
  });
};

export const getTopBuyersListAction = (data) => {
  return postRequest("getTopBuyersList", data).then((res) => {
    return res.data;
  });
};

export const getTopProductsListAction = (data) => {
  return postRequest("getTopProductsList", data).then((res) => {
    return res.data;
  });
};

export const getindustryRevenueAction = (data) => {
  return postRequest("getindustryRevenue", data).then((res) => {
    return res.data;
  });
};

export const getdateRangeAction = (data) => {
  return postRequest("getdateRange", data).then((res) => {
    return res.data;
  });
};

// API Calls
export const getHobsonCategorylistAction = (data) => {
  return postRequest("getallcategories", data).then((res) => {
    return res.data;
  });
};

export const getHobsonProductslistAction = (data) => {
  return postRequest("getHobsonProductslist", data).then((res) => {
    return res.data;
  });
};
export const UpdateProductslistthreadAction = (data) => {
  return postRequest("UpdateProductslistthread", data).then((res) => {
    return res.data;
  });
};

export const getHobsonProductsPriceAction = (data) => {
  return postRequest("getHobsonProductsPrice", data).then((res) => {
    return res.data;
  });
};
// Category page APIs
export const getCatListAction = (data) => {
  return postRequest("getCatList", data).then((res) => {
    return res.data;
  });
};
export const getTotalCatListAction = (data) => {
  return postRequest("getTotalCatList", data).then((res) => {
    return res.data;
  });
};
export const CatBlockUnBlockAction = (data) => {
  return postRequest("CatBlockUnBlock", data).then((res) => {
    return res.data;
  });
};

export const getCategoryDetailsAction = (data) => {
  return postRequest("getCategoryDetails", data).then((res) => {
    return res.data;
  });
};
export const UpdateCatAction = (data) => {
  return postRequest("UpdateCat", data).then((res) => {
    return res.data;
  });
};

export const UpdateCatOrderAction = (data) => {
  return postRequest("UpdateCatOrder", data).then((res) => {
    return res.data;
  });
};
// sub cat
export const getSubCatListAction = (data) => {
  return postRequest("getSubCatList", data).then((res) => {
    return res.data;
  });
};
export const getTotalSubCatListAction = (data) => {
  return postRequest("getTotalSubCatList", data).then((res) => {
    return res.data;
  });
};

// Filters 
export const getFilterTypesListAction = (data) => {
  return postRequest("getFilterTypesList", data).then((res) => {
    return res.data;
  });
};
export const getFilterListAction = (data) => {
  return postRequest("getFilterList", data).then((res) => {
    return res.data;
  });
};
export const getTotalFiltersListAction = (data) => {
  return postRequest("getTotalFiltersList", data).then((res) => {
    return res.data;
  });
};
export const FilterBlockUnBlockAction = (data) => {
  return postRequest("FilterBlockUnBlock", data).then((res) => {
    return res.data;
  });
};

// users 
export const getMonthlySpendlistAction = (data) => {
  return postRequest("getMonthlySpendlist", data).then((res) => {
    return res.data;
  });
};
export const getTieredGrouplistAction = (data) => {
  return postRequest("getTieredGrouplist", data).then((res) => {
    return res.data;
  });
};
export const getindustrylistAction = (data) => {
  return postRequest("getindustrylist", data).then((res) => {
    return res.data;
  });
};
export const getSearchSuburbAction = (data) => {
  return postRequest("getSearchSuburb", data).then((res) => {
    return res.data;
  });
};
export const CreateUserAction = (data) => {
  return postRequest("CreateUser", data).then((res) => {
    return res.data;
  });
};
export const UpdateUserAction = (data) => {
  return postRequest("UpdateUser", data).then((res) => {
    return res.data;
  });
};
export const getUserslistAction = (data) => {
  return postRequest("getUserslist", data).then((res) => {
    return res.data;
  });
};
export const getUserDetailsAction = (data) => {
  return postRequest("getUserDetails", data).then((res) => {
    return res.data;
  });
};
export const getTotalUsersListAction = (data) => {
  return postRequest("getTotalUsersList", data).then((res) => {
    return res.data;
  });
};
export const UserDeleteAction = (data) => {
  return postRequest("UserDelete", data).then((res) => {
    return res.data;
  });
};
export const UserBlockUnBlockAction = (data) => {
  return postRequest("UserBlockUnBlock", data).then((res) => {
    return res.data;
  });
};

export const saveCreditnoteAction=(data)=>{
  return postRequest("saveCreditnote",data).then((res)=>{
    return res.data;
  })
}
export const getCreditNoteAction=(data)=>{
  return postRequest("getCreditnotes",data).then((res)=>{
    return res.data;
  })
}
export const CreditDeleteAction=(data)=>{
  return postRequest("CreditDelete",data).then((res)=>{
    return res.data;
  })
}
export const gettemproductAction =(data)=>{
  return postRequest("searchtemproduct",data).then((res)=>{
    return res.data
  })
}
export const deleteUserCatContractPriceAction =(data)=>{
  return postRequest("deleteUserCatContractPrice",data).then((res)=>{
    return res.data
  })
}
export const getUserCatContractPriceAction =(data)=>{
  return postRequest("getUserCatContractPrice",data).then((res)=>{
    return res.data
  })
}
export const saveCatContractPriceAction =(data)=>{
  return postRequest("saveCatContractPrice",data).then((res)=>{
    return res.data
  })
}
export const saveTemplateAction = (data)=>{
  return postRequest("saveUserTemplate",data).then((res)=>{
    return res.data
  })
}

export const getTempListAction = (data)=>{
  return postRequest("getTempList",data).then((res)=>{
    return res.data
  })
}
export const deleteTemplateAction = (data)=>{
  return postRequest("deleteTemplate",data).then((res)=>{
    return res.data
  })
}

// products
export const getProductslistAction = (data) => {
  return postRequest("getProductslist", data).then((res) => {
    return res.data;
  });
};
export const getTotalProductsListAction = (data) => {
  return postRequest("getTotalProductsList", data).then((res) => {
    return res.data;
  });
};

export const getProductDetailsAction = (data) => {
  return postRequest("getProductDetails", data).then((res) => {
    return res.data;
  });
};
export const getProductContractUsersAction = (data) => {
  return postRequest("getProductContractUsers", data).then((res) => {
    return res.data;
  });
};


export const getUserProductContractAction = (data) => {
  return postRequest("getUserProductContract", data).then((res) => {
    return res.data;
  });
};
export const deleteUserProductContractPriceAction = (data) => {
  return postRequest("deleteUserProductContractPrice", data).then((res) => {
    return res.data;
  });
};



export const getAllActiveUsersListAction = (data) => {
  return postRequest("getAllActiveUsersList", data).then((res) => {
    return res.data;
  });
};
export const getProductsPriceAction= (data)=>{
  return postRequest("getproductsPrice",data).then((res)=>{
    return res.data
  })
}

export const saveproductuserAction= (data)=>{
  return postRequest("saveproductuser",data).then((res)=>{
    return res.data
  })
}

export const searchCategoryAction= (data)=>{
  return postRequest("searchCategory",data).then((res)=>{
    return res.data
  })
}
export const UsercontactPriceideleteAction= (data)=>{
  return postRequest("UsercontactPriceidelete",data).then((res)=>{
    return res.data
  })
}
export const searchFilterProductAction= (data)=>{
  return postRequest("searchFilterProduct",data).then((res)=>{
    return res.data
  })
}

// orders
export const getOrderslistAction = (data) => {
  return postRequest("getOrderslist", data).then((res) => {
    return res.data;
  });
};
export const getTotalOrdersListAction = (data) => {
  return postRequest("getTotalOrdersList", data).then((res) => {
    return res.data;
  });
};

export const getOrdersDetailsAction = (data) => {
  return postRequest("getOrdersDetails", data).then((res) => {
    return res.data;
  });
};
export const getorderStatusAction = (data) => {
  return postRequest("getorderStatus", data).then((res) => {
    return res.data;
  });
};
export const updateOrderStatusAction=(data)=>{
  return postRequest("updateOrderStatus",data).then((res)=>{
    return res.data;
  })
}
export const getShipmentOrderDetailsAction=(data)=>{
  return postRequest("gettingUserShipmentOrderDetails",data).then((res)=>{
    return res.data;
  })
}
export const deleteShipmentOrderDetailsAction=(data)=>{
  return postRequest("deleteShipmentOrderDetails",data).then((res)=>{
    return res.data;
  })
}

export const getAllUserslistAction=(data)=>{
  return postRequest("getAllUserslist",data).then((res)=>{
    return res.data;
  })
}
export const selecteduseraddressAction=(data)=>{
  return postRequest("getuseraddresses",data).then((res)=>{
    return res.data;
  })
}
export const calculateorderShippingAction = (data) => {
  return postRequest("calculateorderShipping", data).then((res) => {
    return res.data;
  });
};
export const CreateUserOrderQuoteAction =(data)=>{
  return postRequest('CreateUserOrderQuote',data).then((res)=>{
    return res.data
  })
}
export const searchorderproductAction =(data)=>{
  return postRequest('searchorderproduct',data).then((res)=>{
    return res.data
  })
}
export const AddtoquoteCartAction =(data)=>{
  return postRequest('AddtoquoteCart',data).then((res)=>{
    return res.data
  })
}
export const getquoteOrderTotalAction =(data)=>{
  return postRequest('getquoteOrderTotal',data).then((res)=>{
    return res.data
  })
}
export const PlaceCODOrderAction =(data)=>{
  return postRequest('Placeorder',data).then((res)=>{
    return res.data
  })
}

export const removecartProductAction =(data)=>{
  return postRequest('removecartProducts',data).then((res)=>{
    return res.data
  })
}
export const updatecartqytAction =(data)=>{
  return postRequest('updatecartqyt',data).then((res)=>{
    return res.data
  })
}
export const UpdateCartProductQtyAction =(data)=>{
  return postRequest('UpdatedCartProductQty',data).then((res)=>{
    return res.data
  })
}



// quotation
export const savequotationAction=(data)=>{
  return postRequest("savequotation",data).then((res)=>{
    return res.data;
  })
}
export const getQuotationDetailsAction=(data)=>{
  return postRequest("getQuotationDetails",data).then((res)=>{
    return res.data;
  })
}
export const getQuotationsListAction=(data)=>{
  return postRequest("getQuotationsList",data).then((res)=>{
    return res.data;
  })
}
export const deleteQuotationAction=(data)=>{
  return postRequest("deleteQuotation",data).then((res)=>{
    return res.data;
  })
}
export const sendQuotationEmailAction=(data)=>{
  return postRequest("sendQuotationEmail",data).then((res)=>{
    return res.data;
  })
}
export const removequoteProductAction=(data)=>{
  return postRequest("removeQuoteProduct",data).then((res)=>{
    return res.data
  })
}

export const updateQuoteDetailAction=(data)=>{
  return postRequest("updateQuoteDetail",data).then((res)=>{
    return res.data
  })
}




export const getUserLoginActivityAction = (data) => {
  return postRequest("getUserLoginActivity", data).then((res) => {
    return res.data;
  });
};

export const getActivityTypesAction = (data) => {
  return postRequest("getactivitytypelist", data).then((res) => {
    return res.data;
  });
};
