import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import { Link, useParams } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'
import { getUserDetailsAction, UpdateUserAction, getSearchSuburbAction, getMonthlySpendlistAction, getTieredGrouplistAction, getindustrylistAction, saveCreditnoteAction, getCreditNoteAction, CreditDeleteAction, getTempListAction, saveTemplateAction, gettemproductAction, deleteTemplateAction, saveproductuserAction, getUserProductContractAction, deleteUserProductContractPriceAction, searchCategoryAction, deleteUserCatContractPriceAction, saveCatContractPriceAction, getUserCatContractPriceAction, getUserLoginActivityAction, getActivityTypesAction } from '../Action/action';
import Cookies from "js-cookie";
const loginData = (!Cookies.get('loginSuccessfastenerwarehouseAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessfastenerwarehouseAdmin'));
const CreateUser = () => {
	if (!loginData || loginData == '') {
		window.location.href = `${config.baseUrl}`;
	}
	if(loginData.role != config.role_admin){
		window.location.href = `dashboard`;	
	}
	const { id } = useParams();
	const [UserDetails, setUserDetails] = useState({});
	const [spinloader, setspinloader] = useState(0);
	const [validationError, setvalidationError] = useState({});
	const [ContractvalidationError, setContractvalidationError] = useState({});
	const [CatContractvalidationError, setCatContractvalidationError] = useState({});
	const [ContractPriceList, setContractPriceList] = useState([]);
	const [CatContractPriceList, setCatContractPriceList] = useState([]);
	const [validationCreditError, setvalidationCreditError] = useState({});
	const [validationTemplateError, setvalidationTemplateError] = useState({});
	const [SuburbsearchResults, setSuburbsearchResults] = useState([]);
	const [industry, setindustry] = useState([]);
	const [tiered_group, settiered_group] = useState([]);
	const [monthly_spend, setmonthly_spend] = useState([]);
	const [suburb, setsuburb] = useState('');
	const [postcode, setpostcode] = useState('');
	const [showcod, setshowcod] = useState(0);
	const [creditNote, setCreditNote] = useState([])
	const [searchProduct, setsearchProduct] = useState([])
	const [searchCategories, setsearchCategories] = useState([])
	const [tempList, setTempList] = useState([])
	const [sertemproducts, setsertemproducts] = useState('')	
	const [Tempproductadd, setTempproductadd] = useState(0)
	const [issubmit, setissubmit] = useState(0);
	const [activityTypes, setActivityTypes] = useState([]);
	const [UserLoginActivityList, setUserLoginActivityList] = useState([]);
	const [validationActivityFilterError, setvalidationActivityFilterError] = useState([]);
	const [contractform, setcontractform] = useState({
		user_id: id,
		price_percentage: '',
		price:0,
		product_id: '',
		hobson_part: ''
	})
	const [Catcontractform, setCatcontractform] = useState({
		user_id: id,
		price_percentage: '',		
		cat_id: '',
		CatTitle: ''
	})
	const [form, setForm] = useState({
		type: "",
		amount: "",
		code: '',
		min_total: '',
		expiry_date: '',
		user_id: id
	});
	const [temForm, setTemForm] = useState({
		title: '',
		templetProduct: [],
		user_id: id
	});
	const [activityFilterForm, setActivityFilteForm] = useState({
		user_id: id,
		activity_type_id: '',
		activity_start_date: '',
		activity_till_date: ''
	});
	useEffect(() => {
		getindustryAPI();
		gettiered_groupAPI();
		getmonthly_spendAPI();
		getuserDetailsAPI();
		getCreditNoteAPI()
		getTempListApi();
		searchtempproduct();
		getUserProductContractAPI();
		getUserCatContractAPI();
		getActivityTypesAPI();
		getUserLoginActivityAPI();
	}, []);
	const getindustryAPI = async () => {
		let res = await getindustrylistAction();
		if (res.success) {
			let data = res.data;
			setindustry(data);
		}
		else {
			setindustry([]);
		}
	}
	const gettiered_groupAPI = async () => {
		let res = await getTieredGrouplistAction();
		if (res.success) {
			let data = res.data;
			settiered_group(data);
		}
		else {
			settiered_group([]);
		}
	}
	const getmonthly_spendAPI = async () => {
		let res = await getMonthlySpendlistAction();
		if (res.success) {
			let data = res.data;
			setmonthly_spend(data);
		}
		else {
			setmonthly_spend([]);
		}
	}
	const getuserDetailsAPI = async () => {
		let res = await getUserDetailsAction({ "user_id": id });
		if (res.success) {
			setUserDetails(res.data);
			setsuburb(res.data.city);
			setpostcode(res.data.postcode);
			if (monthly_spend != '0' && monthly_spend != 1) {
				setshowcod(1)
			}
		}
	};
	const inputHandler = async (e) => {
		const { name, value } = e.target
		setUserDetails((old) => {
			return { ...old, [name]: value }
		})
	}
	const inputmonthly_spendHandler = async (e) => {
		const { name, value } = e.target
		setUserDetails((old) => {
			return { ...old, [name]: value }
		})
		if (value === '') {
			setshowcod(0)
		}
		else if (value != '1') {
			setshowcod(1)
		}
		else {
			setshowcod(0)
		}
	}
	const handleKeyPress = (e) => {
		// Allow only numeric values (0-9) and certain control keys
		const isValidInput = /^[0-9\b]+$/.test(e.key);

		if (!isValidInput) {
			e.preventDefault();
		}
	};
	const SearchSuburb = async (str) => {
		try {
			setsuburb(str);
			setpostcode('');
			setUserDetails((old) => {
				return { ...old, 'city': '' };
			});
			setUserDetails((old) => {
				return { ...old, 'postcode': '' };
			});
			setUserDetails((old) => {
				return { ...old, 'state': '' };
			});
			let res = await getSearchSuburbAction({ str: str });
			setSuburbsearchResults(res.data);
		}
		catch (error) {
			console.error(error);
		}
	};
	const handleSelectSuburb = (row) => {
		console.log(row)
		setsuburb(row.suburb);
		setpostcode(row.postcode);
		setUserDetails((old) => {
			return { ...old, 'city': row.suburb };
		});
		setUserDetails((old) => {
			return { ...old, 'postcode': row.postcode };
		});
		setUserDetails((old) => {
			return { ...old, 'state': row.state };
		});
		setSuburbsearchResults({});
	};
	function validate() {
		let first_nameError = "";
		let last_nameError = "";
		let emailError = "";
		let passwordError = "";
		let phoneError = "";
		let companyError = "";
		let industryError = "";
		let addressError = "";
		let suburbError = "";
		let stateError = "";
		let postcodeError = "";
		let abnError = "";
		let monthly_spendError = "";
		let is_codError = "";
		let minimum_order_totalError = "";
		let free_shippingError = "";
		let minimum_amout_for_free_shippingError = "";
		let tiered_groupError = "";
		let is_activeError = "";
		let codeError = "";
		let amountError = "";
		let typeError = ""

		if (UserDetails.first_name === '') {
			first_nameError = "First name is required."
		}
		if (UserDetails.last_name === '') {
			last_nameError = "Last name is required."
		}
		if (UserDetails.email === '') {
			emailError = "Email is required."
		}
		if (UserDetails.password === '') {
			passwordError = "Password is required."
		}
		if (UserDetails.phone === '') {
			phoneError = "Phone is required."
		}
		if (UserDetails.company === '') {
			companyError = "Company name is required."
		}
		if (UserDetails.industry === '') {
			industryError = "Industry is required."
		}
		if (UserDetails.address_1 === '') {
			addressError = "Address is required."
		}
		if (UserDetails.city === '') {
			suburbError = "Suburb is required."
		}
		if (UserDetails.state === '') {
			stateError = "State is required."
		}
		if (UserDetails.postcode === '') {
			postcodeError = "Postcode is required."
		}
		if (UserDetails.abn === '') {
			abnError = "ABN is required."
		}
		if (UserDetails.monthly_spend === '') {
			monthly_spendError = "Monthly spend is required."
		}
		if (showcod == 1 && UserDetails.is_cod === '') {
			is_codError = "COD or a 30 day is required."
		}
		if (UserDetails.minimum_order_total === '') {
			minimum_order_totalError = "Minimum order total is required."
		}
		if (UserDetails.tiered_group === '') {
			tiered_groupError = "Tiered group is required."
		}
		if (UserDetails.is_active === '') {
			is_activeError = "Status is required."
		}

		if (UserDetails.email != '') {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			if (emailRegex.test(UserDetails.email) === false) {
				emailError = "Please enter valid email."
			}
		}

		if (first_nameError || last_nameError || emailError || passwordError || phoneError || companyError || industryError || addressError || suburbError || stateError || postcodeError || abnError || monthly_spendError || is_codError || minimum_order_totalError || free_shippingError || minimum_amout_for_free_shippingError || tiered_groupError || is_activeError) {
			setvalidationError({
				first_nameError, last_nameError, emailError, passwordError, phoneError, companyError, industryError, addressError, suburbError, stateError, postcodeError, abnError, monthly_spendError, is_codError, minimum_order_totalError, free_shippingError, minimum_amout_for_free_shippingError, tiered_groupError, is_activeError
			})
			return false
		} else {
			setvalidationError({
				first_nameError, last_nameError, emailError, passwordError, phoneError, companyError, industryError, addressError, suburbError, stateError, postcodeError, abnError, monthly_spendError, is_codError, minimum_order_totalError, free_shippingError, minimum_amout_for_free_shippingError, tiered_groupError, is_activeError
			})
			return true
		}
	}
	function validateCredits() {
		let codeError = "";
		let amountError = "";
		let typeError = ""
		let min_totalError = ""
		let expiry_dateError = ""
		if (form.code === "") {
			codeError = "Code is required"
		}

		if (form.amount == "") {
			amountError = "Amount is required"
		}
		if (form.type == "") {
			typeError = "Please select type"
		}
		if (form.min_total == "") {
			min_totalError = "Please enter minimum amount to apply this credit note."
		}
		if (form.expiry_date == "") {
			expiry_dateError = "Please select expiry date."
		}
		if (creditNote.code === form.code) {
			codeError = "Enter new code"
		}


		if (codeError || amountError || typeError || min_totalError || expiry_dateError) {
			setvalidationCreditError({
				codeError, amountError, typeError, min_totalError, expiry_dateError
			})
			return false
		} else {
			setvalidationCreditError({
				codeError, amountError, typeError, min_totalError, expiry_dateError
			})
			return true
		}
	}
	function validateTemplate() {
		let titleError = "";
		let templateError = "";

		if (temForm.title === "") {
			titleError = "Title is required"
		}

		if (temForm.templetProduct.length==0) {
			templateError = "Please select Product"
		}



		if (titleError || templateError) {
			setvalidationTemplateError({
				titleError, templateError,
			})
			return false
		} else {
			setvalidationTemplateError({
				titleError, templateError
			})
			return true
		}
	}
	const SubmitForm = async (e) => {
		e.preventDefault()
		const isValid = validate();
		if (!isValid) {

		}
		else {
			let res = await UpdateUserAction(UserDetails);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}users`;
				}, 1200);
			} else {
				toast.error(res.msg);
			}
		}
	}
	const handlerinput = (e) => {
		const { name, value } = e.target;
		setForm(prevForm => ({ ...prevForm, [name]: value }));
	};
	// const generateCode = () => {
	// 	const newCode = Math.floor(100000 + Math.random() * 900000).toString();
	// 	setCode(newCode);

	// };
	const getCreditNoteAPI = async () => {
		let res = await getCreditNoteAction({ user_id: id });
		if (res.success) {
			let data = res.data;
			setCreditNote(data);
		} else {
			setCreditNote([]);
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		const isValid = validateCredits();
		if (!isValid) {

		} else {
			let res = await saveCreditnoteAction({ ...form })
			if (res.success) {
				toast.success(res.msg)
			} else {
				toast.error(res.msg)
			}
			getCreditNoteAPI()
			setForm({
				type: '',
				amount: '',
				code: '',
				min_total: '',
				expiry_date: '',
				user_id: id

			})
		}
	}
	const CreditDelete = async (id) => {
		Swal.fire({
			title: 'Are you sure? ',
			text: "You want to Delete this credit!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
		}).then(async (result) => {
			if (result.isConfirmed) {
				let res = await CreditDeleteAction({ 'id': id });
				if (res.success) {
					toast.success(res.msg);
					getCreditNoteAPI();
				} else {
					toast.error(res.msg);
				}
			}
		});
	}
	// template
	const searchtempproduct = async (str) => {
		if(str !=''){
		let res = await gettemproductAction({ str: str })
		if (res.success) {
			setsearchProduct(res.data)
		}
		else {
			setsearchProduct([])
		}
		}else{
			setsearchProduct([])
		}
	}	
	const handletempform = (e) => {
		const { name, value } = e.target;
		setTemForm(prevForm => ({ ...prevForm, [name]: value }));
	}
	const handleProductSelect = (row) => {		
		const { id, hobson_part, description, sell_quantity_minimum } = row;
	   
		const productExists = temForm.templetProduct.some(
			product => product.hobson_part === hobson_part
		);

		if (productExists) {
			toast.error("Product already added");
		} else {       
			setTemForm(prevForm => ({
				...prevForm,
				templetProduct: [
					...prevForm.templetProduct,
					{ id, hobson_part, description, sell_quantity_minimum }
				]
			}));

			setsearchProduct([]);
		}
	};
	const handletempSubmit = async (e) => {
		e.preventDefault()
		const isValid = validateTemplate();
		if (!isValid) {

		} else {
			let res = await saveTemplateAction(temForm)
			if (res.success) {
				toast.success(res.msg)
				getTempListApi()
			} else {
				toast.error(res.msg)
			}
			
			setTemForm({
				title: '',
				templetProduct: [],
				user_id: id
			});
		}
	}
	const removetemproduct = (id) => {
		setTemForm(prevForm => ({
			...prevForm,
			templetProduct: prevForm.templetProduct.filter(product => product.id !== id)
		}));
	};
	const getTempListApi = async () => {
		let res = await getTempListAction({ user_id: id });		
		if (res.success) {			
			setTempList(res.data)
		}
		else {
			setTempList([])
		}
	}
	const ViewTempProducts = async (template_id) => {
		
	}
	const deleteUserTemplate = async (template_id) =>{
		Swal.fire({
				title: 'Are you sure? ',
				text: "You want to Delete this Template!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes',
				cancelButtonText: 'No',
			}).then(async (result) => {
				if (result.isConfirmed) {
					let res = await deleteTemplateAction({user_id:id,template_id:template_id});
					if (res.success) {
						toast.success(res.msg);
						getTempListApi();
					} else {
						toast.error(res.msg);					
					}
				}
			});
	}	
	// contract price

	const searchcontactproduct = async (str) => {
		setcontractform((old) => {
			return { ...old, 'hobson_part': str };
		}); 		
		if(str !=''){
		let res = await gettemproductAction({ str: str })
		if (res.success) {
			setsearchProduct(res.data)
		}
		else {
			setsearchProduct([])
		}
		}else{
			setsearchProduct([])
		}
	} 
	
	const handleContractProductSelect = (row) => {		
		const { id, hobson_part } = row;			
		setcontractform((old) => {
			return { ...old, 'product_id': id };
		});
		setcontractform((old) => {
			return { ...old, 'hobson_part': hobson_part };
		}); 
		setsearchProduct([]);
	};
	
	const getUserProductContractAPI = async () => {
		let res = await getUserProductContractAction({ user_id: id });
		if (res.success) {
			let data = res.data;
			setContractPriceList(data);
		} else {
			setContractPriceList([]);
		}
	}
	
	const getUserCatContractAPI = async () => {
		let res = await getUserCatContractPriceAction({ user_id: id });
		if (res.success) {
			let data = res.data;
			setCatContractPriceList(data);
		} else {
			setCatContractPriceList([]);
		}
	}

	const getActivityTypesAPI = async () => {
		let res = await getActivityTypesAction();
		if (res.success) {
			let data = res.data;
			setActivityTypes(data);
		}
		else {
			setActivityTypes([]);
		}
	}
	
	const getUserLoginActivityAPI = async () => {
		let res = await getUserLoginActivityAction(activityFilterForm);
		if (res.success) {
			let data = res.data;
			setUserLoginActivityList(data);
		} else {
			setUserLoginActivityList([]);
		}

	}
	
	const handlerinputActivityFilter = (e) => {
		const { name, value } = e.target;
		setActivityFilteForm(prevAFForm => ({ ...prevAFForm, [name]: value }));
	};


	const inputProducthandler = (e) => {
		const { name, value } = e.target
		setcontractform({
			...contractform,
			[name]: value
		})
	}

	const inputCathandler = (e) => {
		const { name, value } = e.target
		setCatcontractform({
			...Catcontractform,
			[name]: value
		})
	}

	function validateContract() {
		let product_idError = "";
		let priceError = "";
		if (contractform.product_id === '') {
			product_idError = "Product is required."
		}		
		if (contractform.price_percentage === '') {
			priceError = "Price percentage is required."
		}

		if (product_idError || priceError) {
			setContractvalidationError({
				product_idError, priceError
			})
			return false
		} else {
			setContractvalidationError({
				product_idError, priceError
			})
			return true
		}
	}

	const SubmitContractPrice = async (data) => {
		data.preventDefault()
		setissubmit(1);
		const isValid = validateContract();
		if (!isValid) {
			setissubmit(0);
		} else {
			console.log(contractform);
			let res = await saveproductuserAction(contractform)
			if (res.success) {
				setissubmit(0);
				let data = res.data
				toast.success(res.msg)				
				getUserProductContractAPI()
				setcontractform({
					user_id: id,
					price_percentage: '',
					price:0,
					product_id: '',
					hobson_part: ''
				})				
			}else {
				toast.error(res.error)
				setissubmit(0);
			}
		}
	}

	const UserContractProductDelete = async (contract_id) => {		
		Swal.fire({
			title: ' ',
			text: "Are you sure? You want to Delete this contract price!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes',
			cancelButtonText: 'No',
		}).then(async (result) => {
			if (result.isConfirmed) {
				let res = await deleteUserProductContractPriceAction({ id: contract_id });
				if (res.success) {
					toast.success(res.msg);	
					getUserProductContractAPI()
					setcontractform({
						user_id: id,
						price_percentage: '',
						price:0,
						product_id: '',
						hobson_part: ''
					})		
					
				} else {
					toast.error(res.msg);
				}
			}
		});
	}
	
	
	function validateCatContract() {
		let cat_idError = "";
		let priceError = "";
		if (Catcontractform.cat_id === '') {
			cat_idError = "Category is required."
		}		
		if (Catcontractform.price_percentage === '') {
			priceError = "Price percentage is required."
		}

		if (cat_idError || priceError) {
			setCatContractvalidationError({
				cat_idError, priceError
			})
			return false
		} else {
			setCatContractvalidationError({
				cat_idError, priceError
			})
			return true
		}
	}

	const SubmitCatContractPrice = async (data) => {
		data.preventDefault()
		setissubmit(1);
		const isValid = validateCatContract();
		if (!isValid) {
			setissubmit(0);
		} else {
			console.log(Catcontractform);
			let res = await saveCatContractPriceAction(Catcontractform)
			if (res.success) {
				setissubmit(0);				
				toast.success(res.msg)				
				getUserCatContractAPI()
				setCatcontractform({
					user_id: id,
					price_percentage: '',		
					cat_id: '',
					CatTitle: ''
				})				
			}else {
				toast.error(res.error)
				setissubmit(0);
			}
		}
	}
	
	const searchCatcontact = async (str) => {
		setCatcontractform((old) => {
			return { ...old, 'CatTitle': str };
		}); 		
		if(str !=''){
		let res = await searchCategoryAction({ str: str })
		if (res.success) {
			setsearchCategories(res.data)
		}
		else {
			setsearchCategories([])
		}
		}else{
			setsearchCategories([])
		}
	} 
	
	const handleCatContractSelect = (row) => {		
		const { id, CatTitle } = row;			
		setCatcontractform((old) => {
			return { ...old, 'cat_id': id };
		});
		setCatcontractform((old) => {
			return { ...old, 'CatTitle': CatTitle };
		}); 
		setsearchCategories([]);
	};
	
	
	const UserCatContractDelete = async (contract_id) => {		
		Swal.fire({
			title: ' ',
			text: "Are you sure? You want to Delete this contract price!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes',
			cancelButtonText: 'No',
		}).then(async (result) => {
			if (result.isConfirmed) {
				let res = await deleteUserCatContractPriceAction({ id: contract_id });
				if (res.success) {
					toast.success(res.msg);	
					getUserCatContractAPI()
					setcontractform({
						user_id: id,
						price_percentage: '',						
						cat_id: '',
						CatTitle: ''
					})		
					
				} else {
					toast.error(res.msg);
				}
			}
		});
	}
	
	const formatDate = (dateString) => {
		const date = new Date(dateString);
		const options = { day: '2-digit', month: 'short', year: 'numeric' };
		return date.toLocaleDateString('en-GB', options).replace(/ /g, '-');
	  };
	
	function formatDateampm(dateString) {
	  const date = new Date(dateString);
	  
	  const day = String(date.getDate()).padStart(2, '0'); 
	  const month = String(date.getMonth() + 1).padStart(2, '0'); 
	  const year = date.getFullYear(); 
	  const hours = date.getHours() % 12 || 12; 
	  const minutes = String(date.getMinutes()).padStart(2, '0'); 
	  const ampm = date.getHours() >= 12 ? 'PM' : 'AM'; 
	  
	  return `${hours}:${minutes}:${ampm}`;
	}
	
	return (
		<>
			<Sidebar />
			<div className="wrapper d-flex flex-column min-vh-100 bg-light">
				<Toaster />
				<Header />
				<div className="container-lg mb-4">
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb my-0 ms-2">
							<li className="breadcrumb-item">
								<span><a className="homelink" href={`${config.baseUrl}users`} >Home</a></span>
							</li>
							<li className="breadcrumb-item active"><span>Edit User</span></li>
						</ol>
					</nav>
				</div>
				<div className="body flex-grow-1 px-3">
					<div className="container-lg">
						<div className="row">
							<div className="col-md-12">
								<div className="card mb-4">
									<div className="card-header">
										<div className="row align-items-center">
											<div className="col-md-6 text-start">
												<span>Edit User</span>
											</div>
											<div className="col-md-6 text-end">
												<a className="btn btn-primary editbtn text-white" href={`${config.baseUrl}users`} >Back</a>
											</div>
										</div>
									</div>
									<div className="card-body inner-body">
										<Tabs>
											<TabList>
												<Tab>Profile</Tab>
												<Tab>Credit Note</Tab>
												<Tab>Template</Tab>
												<Tab>Product contract Price</Tab>
												<Tab>Category contract Price</Tab>
												<Tab>User Activity</Tab>
											</TabList>
											<TabPanel>
												
												<div className="row mt-3 mb-1">
													<div className="col-md-12 text-end">
														<span class="label-span">Reg. On : {formatDate(UserDetails.created_on)}</span>
													</div>
												</div>					
													
												
												<form className="row g-3">
													<div className="col-md-6">
														<label className="form-label" for="first_name">First name<span className="req-star">*</span></label>
														<input className={validationError.first_nameError ? 'form-control is-invalid' : 'form-control'} id="first_name" name="first_name" placeholder="Enter first name" type="text" onChange={inputHandler} value={UserDetails?.first_name} />
														<div className="invalid-feedback">{validationError.first_nameError}</div>
													</div>
													<div className="col-md-6">
														<label className="form-label" for="last_name">Last name</label>
														<input className="form-control" id="last_name" name="last_name" placeholder="Enter last name" type="text" onChange={inputHandler} value={UserDetails?.last_name} />
													</div>
													<div className="col-md-6">
														<label className="form-label" for="first_name">Email<span className="req-star">*</span></label>
														<input className={validationError.emailError ? 'form-control is-invalid' : 'form-control'} id="email" name="email" placeholder="Enter email address" type="text" onChange={inputHandler} autocomplete="off" value={UserDetails?.email} disabled readonly />
														<div className="invalid-feedback">{validationError.emailError}</div>
													</div>
													<div className="col-md-6">
														<label className="form-label" for="phone">Phone<span className="req-star">*</span></label>
														<input className={validationError.phoneError ? 'form-control is-invalid' : 'form-control'} id="phone" name="phone" placeholder="Enter phone number" type="text" onChange={inputHandler} onKeyPress={handleKeyPress} maxlength="10" value={UserDetails?.phone} />
														<div className="invalid-feedback">{validationError.phoneError}</div>
													</div>
													<div className="col-md-6">
														<label className="form-label" for="company">Company name<span className="req-star">*</span></label>
														<input className={validationError.companyError ? 'form-control is-invalid' : 'form-control'} id="company" name="company" placeholder="Enter company name" type="text" readonly disabled value={UserDetails?.company} />
														<div className="invalid-feedback">{validationError.companyError}</div>
													</div>
													<div className="col-md-6">
														<label className="form-label" for="industry">Industry<span className="req-star">*</span></label>
														<select className={validationError.industryError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="industry" name="industry" onChange={inputHandler} value={UserDetails?.industry}>
															<option value="">Select Industry</option>
															{industry.length > 0 ? (
																industry.map((ind) => (
																	<option key={ind.id} value={ind.id}>{ind.title}</option>
																))
															) : null}
														</select>
														<div className="invalid-feedback">{validationError.industryError}</div>
													</div>
													<div className="col-md-6">
														<label className="form-label" for="abn">ABN<span className="req-star">*</span></label>
														<input className={validationError.abnError ? 'form-control is-invalid' : 'form-control'} id="abn" name="abn" placeholder="Enter ABN" type="text" onChange={inputHandler} value={UserDetails?.abn} />
														<div className="invalid-feedback">{validationError.abnError}</div>
													</div>
													<div className="col-md-12">
														<label className="form-label" for="address">Address<span className="req-star">*</span></label>
														<input className={validationError.addressError ? 'form-control is-invalid' : 'form-control'} id="address_1" name="address_1" placeholder="Enter address" type="text" onChange={inputHandler} value={UserDetails?.address_1} />
														<div className="invalid-feedback">{validationError.addressError}</div>
													</div>
													<div className="col-md-6">
														<label className="form-label" for="suburb">Suburb<span className="req-star">*</span></label>
														<input className={validationError.suburbError ? 'form-control is-invalid' : 'form-control'} id="suburb" name="suburb" placeholder="Enter suburb" type="text" onChange={(e) => SearchSuburb(e.target.value)} autocomplete="off" value={suburb} />
														<span className="invalid-feedback">{validationError.suburbError}</span>
														{SuburbsearchResults.length > 0 ? (
															<ul className="suburbList">
																{SuburbsearchResults.map((row) => (
																	<li key={row.id} onClick={() => handleSelectSuburb(row)}>
																		{row.rowlocation}
																	</li>
																))}
															</ul>
														) : null}
													</div>
													<div className="col-md-6">
														<label className="form-label" for="postcode">Postcode<span className="req-star">*</span></label>
														<input className={validationError.postcodeError ? 'form-control is-invalid' : 'form-control'} id="postcode" name="postcode" value={postcode} placeholder="Enter postcode" type="text" readonly disabled />
														<div className="invalid-feedback">{validationError.postcodeError}</div>
													</div>
													<div className="col-md-6">
														<label className="form-label" for="state">State<span className="req-star">*</span></label>
														<select className={validationError.stateError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="state" name="state" onChange={inputHandler} value={UserDetails?.state} readonly disabled>
															<option value="">Select state</option>
															<option value="ACT">Australian Capital Territory</option>
															<option value="NSW">New South Wales</option>
															<option value="NT">Northern Territory</option>
															<option value="QLD">Queensland</option>
															<option value="SA">South Australia</option>
															<option value="TSA">Tasmania</option>
															<option value="VIC">Victoria</option>
															<option value="WA">Western Australia</option>
														</select>
														<div className="invalid-feedback">{validationError.stateError}</div>
													</div>
													<div className="col-md-6">
														<label className="form-label" for="minimum_order_total">Minimum order total<span className="req-star">*</span></label>
														<input className={validationError.minimum_order_totalError ? 'form-control is-invalid' : 'form-control'} id="minimum_order_total" name="minimum_order_total" placeholder="Enter minimum order total" type="text" onChange={inputHandler} onKeyPress={handleKeyPress} value={UserDetails?.minimum_order_total} />
														<div className="invalid-feedback">{validationError.minimum_order_totalError}</div>
													</div>
													<div className="col-md-6">
														<label className="form-label" for="monthly_spend">Average monthly spend<span className="req-star">*</span></label>
														<select className={validationError.monthly_spendError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="monthly_spend" name="monthly_spend" onChange={inputmonthly_spendHandler} value={UserDetails?.monthly_spend}>
															<option value="">Select average monthly spend</option>
															{monthly_spend.length > 0 ? (
																monthly_spend.map((ms) => (
																	<option key={ms.id} value={ms.id}>{ms.title}</option>
																))
															) : null}
														</select>
														<div className="invalid-feedback">{validationError.monthly_spendError}</div>
													</div>
													<div className="col-md-6">
														<label className="form-label" for="is_cod">Would you like to be on a COD or a 30 day account?<span className="req-star">*</span></label>
														<select className={validationError.is_codError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="is_cod" name="is_cod" onChange={inputHandler} value={UserDetails?.is_cod}>
															<option value="">Select</option>
															<option value="1">COD</option>
															<option value="2">30 Day EOM Credit Account</option>
														</select>
														<div className="invalid-feedback">{validationError.is_codError}</div>
													</div>

													<div className="col-md-4">
														<label className="form-label" for="free_shipping">Free shipping<span className="req-star">*</span></label>
														<select className={validationError.free_shippingError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="free_shipping" name="free_shipping" onChange={inputHandler} value={UserDetails?.free_shipping}>
															<option value="">Select</option>
															<option value="0">No</option>
															<option value="1">Yes</option>
														</select>
														<div className="invalid-feedback">{validationError.free_shippingError}</div>
													</div>
													<div className="col-md-4">
														<label className="form-label" for="minimum_amout_for_free_shipping">Minimum ammount for free shipping<span className="req-star">*</span></label>
														<input className={validationError.minimum_amout_for_free_shippingError ? 'form-control is-invalid' : 'form-control'} id="minimum_amout_for_free_shipping" name="minimum_amout_for_free_shipping" placeholder="Enter minimum order total" type="text" onChange={inputHandler} onKeyPress={handleKeyPress} value={UserDetails?.minimum_amout_for_free_shipping} />
														<div className="invalid-feedback">{validationError.minimum_amout_for_free_shippingError}</div>
													</div>
													<div className="col-md-4">
														<label className="form-label" for="override_global_free_shipping">Override Global Free Shipping</label>
														<select className='form-control' aria-label="select example" id="override_global_free_shipping" name="override_global_free_shipping" onChange={inputHandler} value={UserDetails.override_global_free_shipping}>
															<option value="">Select</option>
															<option value="0">No</option>
															<option value="1">Yes</option>
														</select>
													</div>
													<div className="col-md-6">
														<label className="form-label" for="tiered_group">Tiered group<span className="req-star">*</span></label>
														<select className={validationError.tiered_groupError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="tiered_group" name="tiered_group" onChange={inputHandler} value={UserDetails?.tiered_group}>
															<option value="">Select tiered group</option>
															{tiered_group.length > 0 ? (
																tiered_group.map((tg) => (
																	<option key={tg.id} value={tg.id}>{tg.title}</option>
																))
															) : null}
														</select>
														<div className="invalid-feedback">{validationError.tiered_groupError}</div>
													</div>
													<div className="col-md-6">
														<label className="form-label" for="is_active">Status<span className="req-star">*</span></label>
														<select className={validationError.is_activeError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="is_active" name="is_active" onChange={inputHandler} value={UserDetails?.is_active}>
															<option value="">Select Status</option>
															<option value="0">In-active</option>
															<option value="1">Active</option>
														</select>
														<div className="invalid-feedback">{validationError.is_activeError}</div>
													</div>


													<div className="col-12 text-end">
														<button className="btn btn-primary" type="submit" onClick={SubmitForm} >Update</button>
													</div>

												</form>
											</TabPanel>
											<TabPanel>
												<form onSubmit={handleSubmit}>
													<div className="row mt-4">
														<div className="col-md-2">
															<label className="form-label" htmlFor="type">Credit note type</label>
															<select
																className={validationCreditError.typeError ? 'form-control is-invalid' : 'form-control'}
																id="type"
																name="type"
																value={form.type}
																onChange={handlerinput}
															>
																<option value="">Select Type</option>
																<option value="0">Fixed Amount</option>
																<option value="1">Percentage</option>
															</select>
															<div className="invalid-feedback">{validationCreditError.typeError}</div>
														</div>
														<div className="col-md-2">
															<label className="form-label" htmlFor="amount">Amount</label>
															<input
																className={validationCreditError.amountError ? 'form-control is-invalid' : 'form-control'}
																id="amount"
																name="amount"
																value={form.amount}
																placeholder="Enter amount"
																type="text"
																onChange={handlerinput}
															/>
															<div className="invalid-feedback">{validationCreditError.amountError}</div>
														</div>
														<div className="col-md-2">
															<label className="form-label" htmlFor="amount">Order Amount</label>
															<input
																className={validationCreditError.amountError ? 'form-control is-invalid' : 'form-control'}
																id="min_total"
																name="min_total"
																value={form.min_total}
																placeholder="Enter min. order amount"
																type="text"
																onChange={handlerinput}
															/>
															<div className="invalid-feedback">{validationCreditError.amountError}</div>
														</div>
														<div className="col-md-2">
															<label className="form-label" htmlFor="code">Code</label>
															<input
																className={validationCreditError.codeError ? 'form-control is-invalid' : 'form-control'}
																id="code"
																name="code"
																placeholder="Enter code"
																type="text"
																autoComplete="off"
																value={form.code}

																onChange={handlerinput}
															/>
															<div className="invalid-feedback">{validationCreditError.codeError}</div>
														</div>


														<div className="col-md-2">
															<label className="form-label" htmlFor="amount">Expiry Date</label>
															<input
																className={validationCreditError.expiry_dateError ? 'form-control is-invalid' : 'form-control'}
																id="expiry_date"
																name="expiry_date"
																value={form.expiry_date}
																placeholder="Enter expiry date"
																type="date"
																onChange={handlerinput}
															/>
															<div className="invalid-feedback">{validationCreditError.expiry_dateError}</div>
														</div>


														<div className="col-md-2 mt-25">
															<button className=" btn-primary text-white ht-42" type="submit">Submit</button>
														</div>
													</div>
												</form>
												<table className="table mb-0">
													<thead className="table-light fw-semibold">
														<tr className="align-middle table-heading">
															<th>Code</th>
															<th>Is used</th>
															<th>Amount</th>
															<th>Order Amount</th>
															<th>Expiry date</th>
															<th>Created on</th>
															<th>Type</th>
															<th>Action</th>
														</tr>
													</thead>
													<tbody >
														{spinloader === 0 ? (
															creditNote.length > 0 ? (
																creditNote.map((detail, index) => (
																	<tr key={index}>

																		<td >{detail.code}</td>
																		<td>{detail.is_used == 1 ? 'Yes' : 'No'}</td>
																		<td>{detail.amount}</td>
																		<td>{detail.min_total}</td>
																		<td>{new Date(detail.expiry_date).toLocaleDateString('en-GB')}</td>
																		<td>{new Date(detail.created_on).toLocaleDateString('en-GB')} {new Date(detail.created_on).toLocaleTimeString()}</td>
																		<td>{detail.type == 1 ? 'Percentage' : 'Fixed Amount'}</td>
																		<td><button type="button" className="btn btn-primary deletebtn" onClick={() => CreditDelete(detail.id)}><i className='fa fa-trash'></i> Delete</button>
																		</td>
																	</tr>
																))
															) : (
																<tr>
																	<td colSpan="4" className="text-center">No credit note.</td>
																</tr>
															)
														) : (
															<tr>
																<td colSpan="6" className="text-center">Loading...</td>
															</tr>
														)}
													</tbody>
												</table>
											</TabPanel>
											<TabPanel>
												<form >
													<div className="row mt-4">
														<div className="col-md-5">
															<label className="form-label" htmlFor="title">Template Title</label>
															<input
																className={validationTemplateError.titleError ? 'form-control is-invalid' : 'form-control'}
																name="title"
																onChange={handletempform}
																value={temForm.title}
															/>
															<div className="invalid-feedback">{validationTemplateError.titleError}</div>
														</div>														
														<div className="col-md-3">	
														<label className="form-label" htmlFor="title">Search Product</label>							
															<input
																className={validationTemplateError.templateError ? 'form-control is-invalid' : 'form-control'}
																name="template"
																type="text"
																onChange={(e) => searchtempproduct(e.target.value)}
																autoComplete="off"
																placeholder="Search product"
																value={temForm.template}
															/>
															<span className="invalid-feedback">{validationTemplateError.templateError}</span>
															{searchProduct.length > 0 && (
																<ul className="suburbList">
																	{searchProduct.map((row) => (
																		<li key={row.id} onClick={() => handleProductSelect(row)}>
																			{row.hobson_part}
																		</li>
																	))}
																</ul>
															)}
															<div className="invalid-feedback">{validationCreditError.templateError}</div>
														</div>

													</div>
												</form>

												<div className="row" id="tempproducts" >
														<table className="table mb-0">
															<thead className="table-light fw-semibold">
																<tr className="align-middle table-heading">
																	<th>Name</th>
																	<th>Description</th>
																	<th>Quantity</th>
																	<th>Action</th>
																</tr>
															</thead>
															<tbody>
															{ temForm.templetProduct.length > 0 ? (
																	temForm.templetProduct.map((row, index) => (
																		<tr key={index}>
																			<td>{row.hobson_part}</td>
																			<td>{row.description}</td>
																			<td>{row.sell_quantity_minimum}</td>
																			<td><span className="csr-ptr" onClick={() => removetemproduct(row.id)} > x</span></td>
																		</tr>
																	))
																) : null}
															</tbody>
														</table>
													
												</div>
												{temForm.templetProduct.length > 0 ? (
													<div className="col-md-12 mt-25 text-end">
														<button className="btn btn-primary text-white ht-42" type="submit" onClick={handletempSubmit}>Create Template</button>
													</div>
												) : ""}
												<hr className="mt-5" />
												<table className="table mb-0 ">
													<thead className="table-light fw-semibold">
														<tr className="align-middle table-heading">
															<th>Template title</th>								
															<th>Created on</th>
															<th>Action</th>

														</tr>
													</thead>
													<tbody >
														{ 
															tempList.length > 0 ? (
																tempList.map((detail, index) => (
																	<tr key={index}>
																		<td >{detail.template_title}</td>
																		<td>{new Date(detail.created_on).toLocaleDateString('en-GB')} {new Date(detail.created_on).toLocaleTimeString()}</td>
																		<td><a href="javascript:;" onClick={ () => ViewTempProducts(detail.id)} type="button" className="usetemplete d-none">View Products</a>&nbsp;&nbsp; 
																		 <button type="button" className="btn btn-primary deletebtn"  onClick={ () => deleteUserTemplate(detail.id)} ><i className='fa fa-trash'></i> Delete </button></td>

																	</tr>
																))
															) : (
																<tr>
																	<td colSpan="3" className="text-center">No template.</td>
																</tr>
															)}
													</tbody>
												</table>
											</TabPanel>
											<TabPanel>
												<div className="table-responsive">
													<div className="card-header">	
													<div className="mb-3">
															<div className="col-md-12 text-start">
																<span>Add Users product contract price</span>
															</div>
														</div>					
														<form onSubmit={SubmitContractPrice}>
														<div className="row mt-3">													
																<div className="col-md-4">						
																	<label htmlFor="filtertype" className="form-label">Product*</label>
																	<div className="">	
																	<input
																		className={ContractvalidationError.product_idError ? 'form-control is-invalid' : 'form-control'}
																		name="template"
																		type="text"
																		onChange={(e) => searchcontactproduct(e.target.value)}
																		autoComplete="off"
																		placeholder="Search product"
																		value={contractform.hobson_part}
																	/>
																	<span className="invalid-feedback">{ContractvalidationError.product_idError}</span>
																	{searchProduct.length > 0 && (
																		<ul className="suburbList">
																			{searchProduct.map((row) => (
																				<li key={row.id} onClick={() => handleContractProductSelect(row)}>
																					{row.hobson_part}
																				</li>
																			))}
																		</ul>
																	)}														
																		
																	</div>
																</div>
																<div className="col-md-2">
																	<label htmlFor="Price" className="form-label">Price percentage*</label>
																	<input
																		type="text"
																		name="price_percentage"
																		onChange={inputProducthandler}
																		value={contractform.price_percentage}
																		placeholder="price percentage..."
																		id="price_percentage"
																		data-oc-target="autocomplete-name"
																		className={ContractvalidationError.priceError ? 'form-control is-invalid' : 'form-control'}
																		autoComplete="off"
																		onKeyPress={handleKeyPress}
																	/>
																	<div className="invalid-feedback">{ContractvalidationError.priceError}</div>
																</div>
																<div className="col-md-2 d-none">
																	<label htmlFor="Price" className="form-label">Price</label>
																	<input
																		type="text"
																		name="price"
																		onChange={inputProducthandler}
																		value={form.price}
																		placeholder="price..."
																		id="price"
																		data-oc-target="autocomplete-name"
																		className='form-control'
																		autoComplete="off"
																		onKeyPress={handleKeyPress}
																	/>
																</div>
																<div className="col-md-4 mt-25">
																	<button className="btn-primary text-white ht-42" type="submit" >{issubmit==1 ? 'Submiting...' : 'Submit'}</button>
																</div>
															</div>
														</form>
														<div className="mb-3 mt-5">
															<div className="col-md-12 text-start">
																<span>Products List</span>
															</div>

														</div>
													</div>
													
													<table className="table mb-0">
														<thead className="table-light fw-semibold">
															<tr className="align-middle table-heading">
																<th>Hobson part no</th>
																<th>Description</th>
																<th>Price percentage</th>
																<th>Action</th>
															</tr>
														</thead>

														<tbody >
															{spinloader === 0 ? (
																ContractPriceList.length > 0 ? (
																	ContractPriceList.map((ContractPrice, index) => (
																		<tr key={index}>
																			<td>{ContractPrice.hobson_part}</td>
																			<td>{ContractPrice.description}</td>
																			<td>{ContractPrice.price_percentage}</td>
																			<td><button type="button" className="btn btn-primary deletebtn" onClick={() => UserContractProductDelete(ContractPrice.id)} ><i className='fa fa-trash'></i> Delete</button></td>
																		</tr>
																	))
																) : (
																	<tr>
																		<td colSpan="4" className="text-center">No product found.</td>
																	</tr>
																)
															) : (
																<tr>
																	<td colSpan="6" className="text-center">Loading...</td>
																</tr>
															)}


														</tbody>


													</table>
												</div>
											</TabPanel>
											
											<TabPanel>
												<div className="table-responsive">
													<div className="card-header">	
													<div className="mb-3">
															<div className="col-md-12 text-start">
																<span>Add Users Category contract price</span>
															</div>
														</div>					
														<form onSubmit={SubmitCatContractPrice}>
														<div className="row mt-3">													
																<div className="col-md-4">						
																	<label htmlFor="filtertype" className="form-label">Category*</label>
																	<div className="">	
																	<input
																		className={CatContractvalidationError.catError ? 'form-control is-invalid' : 'form-control'}
																		name="category"
																		type="text"
																		onChange={(e) => searchCatcontact(e.target.value)}
																		autoComplete="off"
																		placeholder="Search category"
																		value={Catcontractform.CatTitle}
																	/>
																	<span className="invalid-feedback">{CatContractvalidationError.categoryError}</span>
																	{searchCategories.length > 0 && (
																		<ul className="suburbList">
																			{searchCategories.map((row) => (
																				<li key={row.id} onClick={() => handleCatContractSelect(row)}>
																					{row.CatTitle}
																				</li>
																			))}
																		</ul>
																	)}														
																		
																	</div>
																</div>
																<div className="col-md-2">
																	<label htmlFor="Price" className="form-label">Price percentage*</label>
																	<input
																		type="text"
																		name="price_percentage"
																		onChange={inputCathandler}
																		value={Catcontractform.price_percentage}
																		placeholder="price percentage..."
																		id="price_percentage"
																		data-oc-target="autocomplete-name"
																		className={CatContractvalidationError.priceError ? 'form-control is-invalid' : 'form-control'}
																		autoComplete="off"
																		onKeyPress={handleKeyPress}
																	/>
																	<div className="invalid-feedback">{CatContractvalidationError.priceError}</div>
																</div>																
																<div className="col-md-4 mt-25">
																	<button className="btn-primary text-white ht-42" type="submit" >{issubmit==1 ? 'Submiting...' : 'Submit'}</button>
																</div>
															</div>
														</form>
														<div className="mb-3 mt-5">
															<div className="col-md-12 text-start">
																<span>Category List</span>
															</div>

														</div>
													</div>
													
													<table className="table mb-0">
														<thead className="table-light fw-semibold">
															<tr className="align-middle table-heading">
																<th>Code</th>
																<th>Description</th>
																<th>Price percentage</th>
																<th>Action</th>
															</tr>
														</thead>

														<tbody >
															{spinloader === 0 ? (
																CatContractPriceList.length > 0 ? (
																	CatContractPriceList.map((CatContractPrice, index) => (
																		<tr key={index}>
																			<td>{CatContractPrice.title}</td>
																			<td>{CatContractPrice.description}</td>
																			<td>{CatContractPrice.price_percentage}</td>
																			<td><button type="button" className="btn btn-primary deletebtn" onClick={() => UserCatContractDelete(CatContractPrice.id)} ><i className='fa fa-trash'></i> Delete</button></td>
																		</tr>
																	))
																) : (
																	<tr>
																		<td colSpan="4" className="text-center">No product found.</td>
																	</tr>
																)
															) : (
																<tr>
																	<td colSpan="6" className="text-center">Loading...</td>
																</tr>
															)}


														</tbody>


													</table>
												</div>
											</TabPanel>
											
											<TabPanel>
												<form className="row g-3">
													<div className="row mt-4">
														<div className="col-md-3">
															<label className="form-label" htmlFor="activity_type">Activity type</label>
															<select
																className="form-control"
																id="activity_type_id"
																name="activity_type_id"
																onChange={handlerinputActivityFilter}
															>
																<option value="">Select Type</option>
																
																{activityTypes.length > 0 ? (
																	activityTypes.map((activity_type) => (
																		<option key={activity_type.id} value={activity_type.id}>{activity_type.title}</option>
																	))
																) : null}
																
																
															</select>
															<div className="invalid-feedback">{validationActivityFilterError.activity_type_idError}</div>
														</div>
														
														
														<div className="col-md-2">
															<label className="form-label" htmlFor="activity_start_date">Activity From Date</label>
															<input
																className="form-control"
																id="activity_start_date"
																name="activity_start_date"
																placeholder="Enter activity from date"
																type="date"
																onChange={handlerinputActivityFilter}
															/>
															<div className="invalid-feedback">{validationActivityFilterError.activity_start_dateError}</div>
														</div>
														
														<div className="col-md-2">
															<label className="form-label" htmlFor="activity_till_date">Activity Till Date</label>
															<input
																className="form-control"
																id="activity_till_date"
																name="activity_till_date"
																placeholder="Enter activity till date"
																type="date"
																onChange={handlerinputActivityFilter}
															/>
															<div className="invalid-feedback">{validationActivityFilterError.activity_till_dateError}</div>
														</div>
														
														
														
														<div className="col-md-2 mt-25">
															<button className=" btn-primary text-white ht-42" type="button" onClick={getUserLoginActivityAPI} >Submit</button>
														</div>
													</div>
												</form>
												
												
												<div className="table-responsive">
													<table className="table mb-0">
														<thead className="table-light fw-semibold">
															<tr className="align-middle table-heading">
																<th width="10%">#</th>
																<th width="20%">Activity Date</th>
																<th width="20%">Activity Type</th>
																<th width="50%">Notes</th>
																
															</tr>
														</thead>

														<tbody >
															{spinloader === 0 ? (
																UserLoginActivityList.length > 0 ? (
																	UserLoginActivityList.map((UserLoginActivity, index) => (
																		<tr key={index}>
																			<td>{index+1}</td>
																			<td>{formatDate(UserLoginActivity.created_on)} {formatDateampm(UserLoginActivity.created_on)}</td>
																			<td>{UserLoginActivity.activity_type}</td>
																			<td>{UserLoginActivity.notes}</td>
																			
																			
																		</tr>
																	))
																) : (
																	<tr>
																		<td colSpan="5" className="text-center">No activity found.</td>
																	</tr>
																)
															) : (
																<tr>
																	<td colSpan="6" className="text-center">Loading...</td>
																</tr>
															)}


														</tbody>


													</table>
												</div>
											</TabPanel>
											
											
										</Tabs>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</>
	)

}
export default CreateUser;
